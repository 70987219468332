//------------------------------------------------------------------------------
import SDK3DVerse from '../sources/SDK3DVerse';
import CameraAPI from '../sources/CameraAPI';

//------------------------------------------------------------------------------
export default class SDK3DVerse_ExtensionInterface
{
    //--------------------------------------------------------------------------
    protected sdk: SDK3DVerse;
    protected cameraAPI: CameraAPI;
    protected name: string;

    constructor(sdk, extensionName)
    {
        this.sdk = sdk;
        this.cameraAPI = sdk.engineAPI.cameraAPI;
        this.name = extensionName;
    }

    //--------------------------------------------------------------------------
    onInit() {}

    //--------------------------------------------------------------------------
    dispose() {}
}
