//------------------------------------------------------------------------------
import SDK3DVerse_ExtensionInterface from 'ExtensionInterface';
import DomRenderer from './DomRenderer';
import Dom3DElement from './Dom3DElement';

//------------------------------------------------------------------------------
export default class SDK3DVerse_ViewportDomOverlay extends SDK3DVerse_ExtensionInterface
{
    //--------------------------------------------------------------------------
    constructor(sdk)
    {
        super(sdk, "ViewportDomOverlay");
        this.domElements    = [];
    }

    //--------------------------------------------------------------------------
    async onInit()
    {
        console.log(`SDK3DVerse_ViewportDomOverlay onInit`);
        this.sdk.domOverlayExt  = this;

        this.installContainer();

        this.sdk.notifier.on('onViewportsUpdated', this.onViewportsUpdated);
        this.sdk.notifier.on('onFramePostRender', this.onFramePostRender);
        this.sdk.notifier.on('onCanvasResized', this.onCanvasResized);
        this.sdk.notifier.on('onDisplayReady', this.installContainer);
    }

    //--------------------------------------------------------------------------
    installContainer = () =>
    {
        this.uninstallContainer();

        this.canvas             = this.sdk.streamerConfig.display.canvas;
        this.viewportContainer  = this.createViewportContainer();
        this.canvas.parentElement.appendChild(this.viewportContainer);

        this.onViewportsUpdated(this.sdk.engineAPI.cameraAPI.getActiveViewports());
        this.onCanvasResized(this.canvas.width, this.canvas.height);
    }

    //--------------------------------------------------------------------------
    dispose()
    {
        this.uninstallContainer();

        this.sdk.notifier.off('onViewportsUpdated', this.onViewportsUpdated);
        this.sdk.notifier.off('onFramePostRender', this.onFramePostRender);
        this.sdk.notifier.off('onCanvasResized', this.onCanvasResized);
        this.sdk.notifier.off('onDisplayReady', this.installContainer);
    }

    //--------------------------------------------------------------------------
    uninstallContainer()
    {
        if(this.viewportContainer)
        {
            this.viewportContainer.remove();
        }

        const viewports = this.sdk.engineAPI.cameraAPI.getActiveViewports();
        for(const viewport of viewports)
        {
            if(viewport.domElement)
            {
                viewport.domElement.remove();
            }

            delete viewport.domElement;
            delete viewport.domRenderer;
        }
    }

    //--------------------------------------------------------------------------
    onViewportsUpdated = (viewports) =>
    {
        this.viewports = viewports;

        for(let viewport of this.viewports)
        {
            if(!viewport.domElement)
            {
                let domElement                  = document.createElement('div');
                domElement.className            = 'SDK3DVerse-viewport';

                domElement.style.position       = 'absolute';
                domElement.style.border         = '1px solid #000000';
                domElement.style.overflow       = 'hidden';

                viewport.domElement = domElement;
            }

            if(!viewport.domRenderer)
            {
                viewport.domRenderer = new DomRenderer();
                viewport.domElement.appendChild(viewport.domRenderer.domElement);
            }

            let dimensions  = viewport.getAreaSize();
            viewport.domRenderer.setSize(dimensions[0], dimensions[1]);
        }

        this.updateViewportDomElements();
    }

    //--------------------------------------------------------------------------
    onFramePostRender = () =>
    {
        for(let viewport of this.viewports)
        {
            if(viewport.domRenderer)
            {
                viewport.domRenderer.render(viewport, this.domElements);
            }
        }
    }

    //--------------------------------------------------------------------------
    onCanvasResized = (width, height) =>
    {
        this.viewportContainer.style.width  = width + 'px';
        this.viewportContainer.style.height = height + 'px';
        this.onViewportsUpdated(this.sdk.engineAPI.cameraAPI.getActiveViewports());
    }

    //--------------------------------------------------------------------------
    updateViewportDomElements()
    {
        for(let i = 0; i < this.viewportContainer.children.length;)
        {
            var domElement  = this.viewportContainer.children[i];
            var isVisible   = this.viewports.find((viewport) => viewport.domElement == domElement);

            if(!isVisible)
            {
                this.viewportContainer.removeChild(domElement);
            }
            else
            {
                ++i;
            }
        }

        for(let viewport of this.viewports)
        {
            if(!viewport.domElement)
            {
                continue;
            }
            if(!viewport.domElement.parentElement)
            {
                this.viewportContainer.appendChild(viewport.domElement);
            }

            let dimensions  = viewport.getAreaSize();
            let offset      = viewport.getOffset();

            viewport.domElement.style.width     = dimensions[0] + "px";
            viewport.domElement.style.height    = dimensions[1] + "px";
            viewport.domElement.style.left      = offset[0] + "px";
            viewport.domElement.style.top       = offset[1] + "px";
        }
    }

    //--------------------------------------------------------------------------
    createViewportContainer()
    {
        let domElement                  = document.createElement('div');
        domElement.className            = 'SDK3DVerse-viewport-container';
        domElement.style.pointerEvents  = 'none';
        domElement.style.position       = 'absolute';

        return domElement;
    }

    //--------------------------------------------------------------------------
    createDomElement(domElement, enableScale = false)
    {
        let instance = new Dom3DElement(domElement, this.sdk, enableScale);
        this.domElements.push(instance);
        return instance;
    }

    //--------------------------------------------------------------------------
    releaseDomElement(domElement)
    {
        domElement.delete();
        var index = this.domElements.findIndex((h) => h == domElement);
        this.domElements.splice(index, 1);
    }
}
