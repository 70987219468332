//------------------------------------------------------------------------------
const MOUSE_EVENTS      = ['click', 'mousedown', 'mouseup', 'mousemove', 'mouseenter', 'mouseleave', 'mouseover', 'mouseout', 'contextmenu'];
const TOUCH_EVENTS      = ['touchstart', 'touchend', 'touchmove', 'touchcancel'];
const POINTER_EVENTS    = ['pointerover', 'pointerenter', 'pointerdown', 'pointermove', 'pointerup', 'pointercancel', 'pointerout', 'pointerleave', 'gotpointercapture', 'lostpointercapture'];
const ALL_EVENTS        = [...MOUSE_EVENTS, ...TOUCH_EVENTS, ...POINTER_EVENTS];

//------------------------------------------------------------------------------
export default class Dom3DElement
{
    //--------------------------------------------------------------------------
    constructor(domElement, sdk, enableScale)
    {
        this.domElement     = domElement;
        this.sdk            = sdk;

        this.clonedNodes    = new Map();
        this.position       = vec3.create();
        this.isEnabled      = true;
    }

    //--------------------------------------------------------------------------
    getInstance(viewport)
    {
        let cloneNode = this.clonedNodes.get(viewport.id);

        if(!cloneNode)
        {
            cloneNode = this.domElement.cloneNode(1);

            const clickHandler = () =>
            {
                this.sdk.engineAPI.cameraAPI.setActiveViewport(viewport);
            };

            const releaseHandler = () =>
            {
                this.sdk.notifier.emit('onViewportSelected', viewport);
            };

            cloneNode.onmousedown   = clickHandler;
            cloneNode.onmouseup     = releaseHandler;

            cloneNode.ontouchstart  = clickHandler;
            cloneNode.ontouchend    = releaseHandler;
            cloneNode.ontouchcancel = releaseHandler;
            cloneNode.ontouchleave  = releaseHandler;

            for(const event of ALL_EVENTS)
            {
                const eventName = `on${event}`;
                if (typeof this.domElement[eventName] === 'function')
                {
                    cloneNode[eventName] = (event) =>
                    {
                        return this.domElement[eventName](event, viewport);
                    };
                }
            }

            cloneNode.style.position = 'absolute';
            if(cloneNode.id)
            {
                cloneNode.id = 'viewport-' + viewport.id + '-' + cloneNode.id;
            }

            this.clonedNodes.set(viewport.id, cloneNode);
        }

        return cloneNode;
    }

    //--------------------------------------------------------------------------
    propagateChangesToClones()
    {
        const innerHTML = this.domElement.innerHTML;
        for(const cloneNode of this.clonedNodes.values())
        {
            cloneNode.innerHTML = innerHTML;
        }
    }

    //--------------------------------------------------------------------------
    propagateClassNameChange(className)
    {
        for(const cloneNode of this.clonedNodes.values())
        {
            cloneNode.className = className;
        }
    }

    //--------------------------------------------------------------------------
    propagateStyleChange(propertyName, value, priority)
    {
        for(const cloneNode of this.clonedNodes.values())
        {
            cloneNode.style.setProperty(propertyName, value, priority);
        }
    }

    //--------------------------------------------------------------------------
    delete()
    {
        for(const cloneNode of this.clonedNodes.values())
        {
            if(cloneNode.parentNode != null)
            {
                cloneNode.parentNode.removeChild(cloneNode);
            }
        }
    }
}
