//------------------------------------------------------------------------------
export default class DomRenderer
{
    //--------------------------------------------------------------------------
    constructor()
    {
        this.domElement = document.createElement( 'div' );
        this.domElement.style.overflow = 'hidden';
    }

    //--------------------------------------------------------------------------
    setSize(width, height)
    {
        this.width  = width;
        this.height = height;

        this.widthHalf  = width / 2;
        this.heightHalf = height / 2;

        this.domElement.style.width  = width + 'px';
        this.domElement.style.height = height + 'px';
    }

    //--------------------------------------------------------------------------
    render(viewport, html3DElements)
    {
        const disableRender = viewport.disableViewportDomOverlay;
        if(disableRender)
        {
            const isVisible = false;
            for(const html3dElement of html3DElements)
            {
                this.renderObject(html3dElement.getInstance(viewport), null, 0, isVisible, false);
            }
            return;
        }

        const viewProjectionMatrix = viewport.getViewProjectionMatrix();
        for(let html3dElement of html3DElements)
        {
            html3dElement.projectedPosition = vec3.create();
            vec3.transformMat4(html3dElement.projectedPosition, html3dElement.position, viewProjectionMatrix);
        }

        html3DElements.sort((a, b) => b.projectedPosition[2] - a.projectedPosition[2]);

        for(let i in html3DElements)
        {
            const html3dElement =  html3DElements[i];
            const isVisible     =  html3dElement.projectedPosition[0] > -1.1
                                && html3dElement.projectedPosition[0] < 1.1
                                && html3dElement.projectedPosition[1] > -1.1
                                && html3dElement.projectedPosition[1] < 1.1
                                && html3dElement.projectedPosition[2] < 1.00
                                && html3dElement.projectedPosition[2] > -0.01;

            this.renderObject(
                html3dElement.getInstance(viewport),
                html3dElement.projectedPosition,
                i,
                isVisible,
                html3dElement.isEnabled,
            );
        }
    }

    //--------------------------------------------------------------------------
    renderObject(element, position, index, isVisible, isEnabled, scale = 0)
    {
        if(isVisible)
        {
            let style = 'translate(-50%,-50%) ';
            style    += 'translate(' + ( position[0] * this.widthHalf + this.widthHalf ) + 'px,' + ( - position[1] * this.heightHalf + this.heightHalf ) + 'px)';

            if(scale)
            {
                style += ' scale(' + scale + ')';
            }

            element.style.WebkitTransform   = style;
            element.style.MozTransform      = style;
            element.style.oTransform        = style;
            element.style.transform         = style;
            element.style.zIndex            = index;
            element.style.pointerEvents     = isEnabled ? 'auto' : 'none';

            if (element.parentNode !== this.domElement)
            {
                this.domElement.appendChild(element);
            }
        }
        else
        {
            if (element.parentNode === this.domElement)
            {
                this.domElement.removeChild(element);
            }
        }
    }
}
